<template>
  <DsPageFooter class="status-footer">
    <template #primaryNavigation>
      <p class="ds-color-white ds-display-flex ds-align-items-center">
        <span>{{ footerLabel }}</span>&nbsp;
        <DsLink
          :content="footerLink.label"
          type="link"
          color="white"
          variant="secondary"
          :open-in-new-window="true"
          :url="footerLink.url"
          :style="{ marginTop: '-1px' }"
        />
      </p>
    </template>
    <template #secondaryNavigation>
      <DsFooterSecondaryNavigation :items="footerSecondaryNavigation" />
    </template>
    <template #socialBar>
      <DsSocialBar
        v-if="footerSocialItems.length"
        :items="footerSocialItems"
        theme="dark"
        disposition="horizontal"
      />
    </template>
  </DsPageFooter>
</template>

<script>
import DsPageFooter from 'website-design-system/src/templates/page-footer/page-footer'
import DsFooterSecondaryNavigation from 'website-design-system/src/templates/page-footer/components/footer-secondary-navigation'
import DsSocialBar from 'website-design-system/src/molecules/social-bar/social-bar'
import DsLink from 'website-design-system/src/molecules/link/link'

export default {
  name: 'PageLayoutFooter',
  components: {
    DsPageFooter,
    DsFooterSecondaryNavigation,
    DsSocialBar,
    DsLink
  },
  props: {
    footerLabel: {
      type: String,
      default: ''
    },
    footerLink: {
      type: Object,
      default: null
    },
    footerSocialItems: {
      type: Array,
      default: () => ([])
    },
    footerSecondaryNavigation: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="scss" scoped>
.status-footer {
  :deep(.ds-text-icon) {
    align-items: center;
    display: flex;
  }
}
</style>
