<template>
  <DsPageHeader :screen="screen.name" :theme="headerTheme">
    <template #logo>
      <router-link
        to="/"
        data-track-component-name="DsPageHeader"
        data-track-level="1"
        :data-track-text="logoLabel || logoAriaLabel"
      >
        <DsLogo
          :aria-label="logoAriaLabel"
          :label="logoLabel"
        />
      </router-link>
    </template>
  </DsPageHeader>
</template>

<script>
import DsPageHeader from 'website-design-system/src/templates/page-header/page-header'
import DsLogo from 'website-design-system/src/atoms/logo/logo'

export default {
  name: 'PageLayoutHeader',
  components: { DsPageHeader, DsLogo },
  inject: ['screen'],
  props: {
    headerTheme: {
      type: String,
      required: true
    },
    logoLabel: {
      type: String,
      default: ''
    },
    logoAriaLabel: {
      type: String,
      default: ''
    }
  }
}
</script>
