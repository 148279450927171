import { createRouter, createWebHistory } from 'vue-router'
import { MESSAGE_TYPE } from '@/constants';

const isValid = (param) => {
  const expectedFormat = /[a-z]+-[0-9]{4}/
  return expectedFormat.test(param)
}

const beforeEnter = (to, from, next) => {
  if (!isValid(to.params.id)) {
    next({ path: '/404' })
    return
  }

  next()
}

const routes = [
  {
    path: '/',
    component: () => import('../views/home.vue')
  },
  {
    path: '/incident-history/:id',
    component: () => import('../views/details-page.vue'),
    props: { type: MESSAGE_TYPE.INCIDENT },
    beforeEnter
  },
  {
    path: '/incident-history',
    component: () => import('../views/history-page.vue'),
    props: { type: MESSAGE_TYPE.INCIDENT }
  },
  // @TODO: validate with product if we need to bring this pages back, otherwise we can remove them
  // {
  //   path: '/maintenance-history/:id',
  //   component: () => import('../views/details-page.vue'),
  //   props: { type: MESSAGE_TYPE.MAINTENANCE },
  //   beforeEnter
  // },
  // {
  //   path: '/maintenance-history',
  //   component: () => import('../views/history-page.vue'),
  //   props: { type: MESSAGE_TYPE.MAINTENANCE }
  // },
  {
    path: '/404',
    component: () => import('../views/404.vue'),
    props: { headerTheme: 'white' }
  },
  {
    path: '/error',
    component: () => import('../views/error.vue'),
    props: { headerTheme: 'white' }
  },
  {
    path: '/:catchAll(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router
